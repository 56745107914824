import { render, staticRenderFns } from "./cerfiticate.vue?vue&type=template&id=6a0cd973&scoped=true&"
import script from "./cerfiticate.vue?vue&type=script&lang=js&"
export * from "./cerfiticate.vue?vue&type=script&lang=js&"
import style0 from "./cerfiticate.vue?vue&type=style&index=0&id=6a0cd973&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0cd973",
  null
  
)

export default component.exports